@import "../../../styles/global.scss";

p {
  width: 100%;
}

.chalPromptForm {
  padding: 0 40px;

  h1 {
    text-align: center;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
    padding-bottom: 0;
    text-align: center;
  }

  .inputs {
    width: 100%;
    justify-content: center;
    z-index: 1;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid $fm_blue;

    &:last-of-type {
      border: none;
      padding-bottom: 0;
      margin-bottom: 1.5rem;
    }
  }

  .basicOptions {
    @extend .inputs;
  }

  .advancedOptions {
    @extend .inputs;

    p {
      margin-bottom: 1.5rem;
    }

    .advancedOptionsInputs {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }

    .numericInputContainer {
      margin: auto;
      display: flex;
      flex-direction: row;
      height: 30px;
      margin-bottom: 10px;
      width: 100%;
      text-align: center;

      label {
        width: 50%;
        margin-top: auto;
        margin-bottom: auto;
        text-align: right;
      }

      select {
        @extend %roundInput;
        border-radius: 10px;
        width: 30%;
        margin-left: 20px;
        height: 100%;
      }

      >span {
        width: 10%;
        display: grid;
        place-items: center;
      }
    }
  }


  button[type="submit"] {
    @extend %btnPrimary;
    display: block;
    margin: 0 auto;
    //min-width: 200px; // removed fixed width to align all buttons in center 
  }
  .btnGroup { 
    @extend %btnGroup;
    justify-content: center;
    button {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}

.fodmapLists {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 2rem 0;
}

.leftColumn {
  width: 45%;
  margin: 0 70px;

  .radioList {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.radioList {
  width: 45%;
  margin: 0 70px;

  h3 {
    color: $dark_gray;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.1rem;
  }

  >div {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
}

.languageSelect {
  width: fit-content;
  margin: 2rem auto 0;
}

.reminderForm {
  padding: 2rem;

  h1 {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 2rem;
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 2rem;

    button {
      min-width: 120px;
    }
  }
}

.success,
.error {
  text-align: center;
  padding: 2rem;

  h1 {
    margin-bottom: 1rem;
  }
}

.columns {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;

  .column {
    width: 45%;
    margin: 0 70px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    section {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }

      h3 {
        color: $dark_gray;
        text-align: center;
        margin-bottom: 0;
        margin-top: 0;
        font-size: 1.1rem;
      }

      >div {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }
    }
  }
}

.clinicianField {
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 20px;

  .labelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    label.clinicianLabel {
      font-weight: 600;
      font-size: 1.1rem;
      color: #333; /* Dark gray/black color */
      margin-bottom: 0;
    }
  }
}

.selectDropdown {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background: white;
  cursor: pointer;
  appearance: none; /* Removes default styling in some browsers */
}

.selectDropdown:focus {
  outline: none;
  border-color: #007bff; /* Adds a highlight on focus */
}

.hideAdvancedOptions {
  display: none !important;
}
